/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logout } from '../../actions/session.action';
import './styles.css';
import '../../Pages/css/login.css';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
    this.handleLogout = this.handleLogout.bind(this);
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  handleLogout() {
    const { logoutAction } = this.props;
    sessionStorage.removeItem('jwt');
    logoutAction();
    window.location = '/login';
  }

  render() {
    const { errorInfo, error } = this.state;
    const { children } = this.props;
    if (errorInfo) {
      // Error path
      return (
        <div className="error-container">
          <div className="Login-info-logo">
            <img src="/bento-logo.svg" alt="Bento Logo" />
            <span>Bento</span>
          </div>
          <div className="error-message">
            <div>
              <h2>Something went wrong.</h2>
              <p>
                An error has occurred. Please refresh the page by{' '}
                <a href={window.location.pathname}>clicking here</a> or click below to log back in.
                <br />
                And if the problem persist contact{' '}
                <a href="mailto:bento@kitamba.com">bento@kitamba.com</a>
              </p>
              <button className="btn btn-custom dark" onClick={this.handleLogout} type="button">
                Log out
              </button>
            </div>
            <img src="/modal-message-error-upload.svg" alt="global error" />
          </div>
          {process.env.NODE_ENV !== 'production' && (
            <details style={{ whiteSpace: 'pre-wrap' }}>
              <code>
                {error && error.toString()}
                <br />
                {errorInfo?.componentStack}
              </code>
            </details>
          )}
        </div>
      );
    }
    // Normally, just render children
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.element.isRequired,
  logoutAction: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  logoutAction: logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);
